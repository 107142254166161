// const currentUrl = window.location.href; // Get the current URL dynamically
export const defaultUrl = "https://afomedia.com/";

export const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=`;
export const twitterShareUrl = `https://twitter.com/intent/tweet?url=`;
export const whatsappShareUrl = `https://api.whatsapp.com/send?text=%20%0A`;
export const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=`;
export const instagramShareUrl = `https://www.instagram.com/?url=`;
export const snapchatShareUrl = `https://www.snapchat.com/share?url=`;
export const tiktokShareUrl = `https://www.tiktok.com/share?url=`;
export const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=`;
export const telegramShareUrl = `https://telegram.me/share/url?url=`;
export const redditShareUrl = `https://www.reddit.com/submit?url=`;
export const quoraShareUrl = `https://www.quora.com/share?url=`;


export function generateFacebookShareUrl(data,  category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${data?.slug}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${facebookShareUrl}${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}
export function generateTwitterShareUrl(data,  category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${data?.slug}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${twitterShareUrl}${encodedUrl}&text=${encodedTitle}%20-%20${encodedDescription}`;
}

export function generateWhatsappShareUrl(data,  category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${data?.slug}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${whatsappShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}

// Generate share URL for LinkedIn
export function generateLinkedinShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${linkedinShareUrl}${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`;
}

// Generate share URL for Instagram
export function generateInstagramShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${instagramShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Generate share URL for Snapchat
export function generateSnapchatShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${snapchatShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Generate share URL for TikTok
export function generateTikTokShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${tiktokShareUrl}${encodedUrl}&title=${encodedTitle}&desc=${encodedDescription}`;
}

// Pinterest share URL
export function generatePinterestShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${pinterestShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// Telegram share URL
export function generateTelegramShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${telegramShareUrl}?url=${encodedUrl}&text=${encodedTitle}`;
}

// Reddit share URL
export function generateRedditShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${redditShareUrl}?url=${encodedUrl}&title=${encodedTitle}`;
}

// Quora share URL
export function generateQuoraShareUrl(data, category) {
  const encodedTitle = encodeURIComponent(
    data?.media?.title || data?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    data?.media?.description || data?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${data?.slug}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${data?._id}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${data?._id}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${data?._id}`);
  }

  return `${quoraShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}



//----GENRE -----
export function genreFacebookShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${objId}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}${objId}`);
  }

  return `${facebookShareUrl}${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

export function genreTwitterShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${objId}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}${objId}`);
  }

  return `${twitterShareUrl}${encodedUrl}&text=${encodedTitle}%20-%20${encodedDescription}`;
}

export function genreWhatsappShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );
  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(
      `${defaultUrl}videos/${objId}`
    );
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${whatsappShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}



// genre share URL for LinkedIn
export function genreLinkedinShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${linkedinShareUrl}${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`;
}

// genre share URL for Instagram
export function genreInstagramShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${instagramShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// genre share URL for Snapchat
export function genreSnapchatShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${snapchatShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// genre share URL for TikTok
export function genreTikTokShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${tiktokShareUrl}${encodedUrl}&title=${encodedTitle}&desc=${encodedDescription}`;
}

//genre Pinterest share URL
export function genrePinterestShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${pinterestShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// genre Telegram share URL
export function genreTelegramShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${telegramShareUrl}?url=${encodedUrl}&text=${encodedTitle}`;
}

//genre Reddit share URL
export function genreRedditShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${redditShareUrl}?url=${encodedUrl}&title=${encodedTitle}`;
}

//genre Quora share URL
export function genreQuoraShareUrl(Obj,  category, objId) {
  const encodedTitle = encodeURIComponent(
    Obj?.media?.title || Obj?.title || ""
  );
  const encodedDescription = encodeURIComponent(
    Obj?.media?.description || Obj?.description || ""
  );

  let encodedUrl;

  if (category === "movie" || category === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}videos/${objId}`);
  } else if (category === "podcast" || category === "show" || category === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${objId}`);
  } else if (category === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${objId}`);
  } else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${objId}`);
  }

  return `${quoraShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

//------- show info
export function showInfoFacebookShareUrl(id, mediaCategory, title, description) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${facebookShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}

export function showInfoTwitterShareUrl(id, mediaCategory, title, description) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${twitterShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
export function showInfoWhatsappShareUrl(id, mediaCategory, title, description) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${whatsappShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}


// showInfo share URL for LinkedIn
export function showInfoLinkedinShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }
  return `${linkedinShareUrl}${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`;
}

// showInfo share URL for Instagram
export function showInfoInstagramShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }
  return `${instagramShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// showInfo share URL for Snapchat
export function showInfoSnapchatShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${snapchatShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// showInfo share URL for TikTok
export function showInfoTikTokShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${tiktokShareUrl}${encodedUrl}&title=${encodedTitle}&desc=${encodedDescription}`;
}

//showInfo Pinterest share URL
export function showInfoPinterestShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${pinterestShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// showInfo Telegram share URL
export function showInfoTelegramShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${telegramShareUrl}?url=${encodedUrl}&text=${encodedTitle}`;
}

// showInfo Reddit share URL
export function showInfoRedditShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${redditShareUrl}?url=${encodedUrl}&title=${encodedTitle}`;
}

// showInfo Quora share URL
export function showInfoQuoraShareUrl(id, mediaCategory, title, description) {
  const encodedTitle = encodeURIComponent(title || '');
  const encodedDescription = encodeURIComponent(description || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}show-info/${id}`);
  }

  return `${quoraShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}
//----

///------ episode
export function EpisodeFbShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${facebookShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}

export function EpisodeTwitterShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${twitterShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
export function EpisodeWhatsappShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${whatsappShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
// Episode share URL for LinkedIn
export function EpisodeLinkedinShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;
  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }
  return `${linkedinShareUrl}${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`;
}

// Episode share URL for Instagram
export function EpisodeInstagramShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }
  return `${instagramShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Episode share URL for Snapchat
export function EpisodeSnapchatShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }
  return `${snapchatShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Episode share URL for TikTok
export function EpisodeTikTokShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${tiktokShareUrl}${encodedUrl}&title=${encodedTitle}&desc=${encodedDescription}`;
}

//Episode Pinterest share URL
export function EpisodePinterestShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }
  return `${pinterestShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// Episode Telegram share URL
export function EpisodeTelegramShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${telegramShareUrl}?url=${encodedUrl}&text=${encodedTitle}`;
}

// Episode Reddit share URL
export function EpisodeRedditShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${redditShareUrl}?url=${encodedUrl}&title=${encodedTitle}`;
}

// Episode Quora share URL
export function EpisodeQuoraShareUrl(episodeObjectId, mediaCategory, EPtitle, EPDesc) {
  const encodedTitle = encodeURIComponent(EPtitle || '');
  const encodedDescription = encodeURIComponent(EPDesc || '');
  let encodedUrl;

  if (mediaCategory === "podcast" || mediaCategory === "show" || mediaCategory === "tvshow") {
    encodedUrl = encodeURIComponent(`${defaultUrl}episode/${episodeObjectId}`);
  }

  return `${quoraShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

//-----------Video
export function VideoFbShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;
  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${facebookShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}

export function VideoTwitterShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;
  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${twitterShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
export function VideoWhatsappShareUrl(id, mediaCategory, movieTitle, mediaDesc, linkUrl) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;
  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${whatsappShareUrl}${linkUrl}%0A${encodedTitle}`;
}

// Episode share URL for LinkedIn
export function VideoLinkedinShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;
  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }
  return `${linkedinShareUrl}${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`;
}

// Episode share URL for Instagram
export function VideoInstagramShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }
  return `${instagramShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Episode share URL for Snapchat
export function VideoSnapchatShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }
  return `${snapchatShareUrl}${encodedUrl}&title=${encodedTitle}&caption=${encodedDescription}`;
}

// Episode share URL for TikTok
export function VideoTikTokShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${tiktokShareUrl}${encodedUrl}&title=${encodedTitle}&desc=${encodedDescription}`;
}

//Episode Pinterest share URL
export function VideoPinterestShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }
  return `${pinterestShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// Episode Telegram share URL
export function VideoTelegramShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${telegramShareUrl}?url=${encodedUrl}&text=${encodedTitle}`;
}

// Episode Reddit share URL
export function VideoRedditShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;
  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${redditShareUrl}?url=${encodedUrl}&title=${encodedTitle}`;
}

// Episode Quora share URL
export function VideoQuoraShareUrl(id, mediaCategory, movieTitle, mediaDesc) {
  const encodedTitle = encodeURIComponent(movieTitle || '');
  const encodedDescription = encodeURIComponent(mediaDesc || '');
  let encodedUrl;

  if (mediaCategory === "movie" || mediaCategory === "video") {
    encodedUrl = encodeURIComponent(`${defaultUrl}video-details/app/${id}`);
  }

  return `${quoraShareUrl}?url=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}`;
}

// live channel page

export function liveFacebookShareUrl(channelID, channelType, channelTitle, channelDescripton) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(channelTitle || '');
  const encodedDescription = encodeURIComponent(channelDescripton || '');
  let encodedUrl;
  if (channelType === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${channelID}`);
  }
  else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${channelID}`);
  }

  return `${facebookShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}

export function liveTwitterShareUrl(channelID, channelType, channelTitle, channelDescripton) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(channelTitle || '');
  const encodedDescription = encodeURIComponent(channelDescripton || '');
  let encodedUrl;
  if (channelType === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${channelID}`);
  }
  else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${channelID}`);
  }

  return `${twitterShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
export function liveWhatsappShareUrl(channelID, channelType, channelTitle, channelDescripton) {

  // Modify the following lines based on your specific logic
  const encodedTitle = encodeURIComponent(channelTitle || '');
  const encodedDescription = encodeURIComponent(channelDescripton || '');
  let encodedUrl;
  if (channelType === "m3u") {
    encodedUrl = encodeURIComponent(`${defaultUrl}live-channel/${channelID}`);
  }
  else {
    encodedUrl = encodeURIComponent(`${defaultUrl}live/${channelID}`);
  }


  return `${whatsappShareUrl}${encodedUrl}%0A${encodedTitle}%0A${encodedDescription}`;
}
